import React from "react"
import { shape, string, arrayOf } from "prop-types"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Fade from "react-reveal/Fade"
import Layout from "../components/layout"
import SEO from "../components/seo"
import theme from "../config/theme"
import ContentBlocks from "../components/content-blocks"
import BrandHeader from "../components/molecules/brand-header"
import Products from "../components/molecules/products"
import HeroImage from "../components/molecules/hero-image"
// import { getFallbackImageFluid } from "../helpers/index"

const propTypes = {
  data: shape({
    contentfulCategory: shape({
      title: string,
    }),
    allFiImages: shape({
      edges: arrayOf(),
    }),
  }).isRequired,
  pageContext: shape().isRequired,
  path: string.isRequired,
  intl: shape().isRequired,
  location: shape().isRequired,
}

const Brand = ({ intl, data, pageContext, path, location }) => {
  const { languages: slugs } = pageContext
  const { edges: productFiImages } = data.allFiImages
  const {
    title,
    image,
    contentBlocks,
    description,
    homepage,
    product,
  } = data.contentfulBrand

  const productTabs = data.allContentfulSubCategory.edges.map((e) => {
    return {
      title: e.node.title,
      id: `id-${e.node.id}`,
      products: e.node.product,
    }
  })
  productTabs.unshift({
    title: intl.formatMessage({ id: "product.all_products" }),
    id: "products",
    products: product,
  })

  return (
    <Layout
      slugs={slugs}
      colourTheme="mercury"
      currentPath={path}
      haProductsHeader
    >
      <SEO title={title} location={location.pathname} />
      <Fade bottom distance="3em" delay={50} duration={1500}>
        <HeroImage image={image} />
      </Fade>
      <Fade force bottom distance="3em" delay={300} duration={1500}>
        <BrandHeader
          title={title}
          description={description}
          homepage={homepage}
          theme={theme}
        />
      </Fade>
      <ContentBlocks delayOffset={600} blocks={contentBlocks} />
      <Fade force bottom distance="3em" delay={900} duration={1500}>
        <Products productImages={productFiImages} productTabs={productTabs} />
      </Fade>
    </Layout>
  )
}

export default injectIntl(Brand)

Brand.propTypes = propTypes

export const brandQuery = graphql`
  query(
    $id: String!
    $contentful_id: String!
    $productTabs: [String]!
    $locale: String!
  ) {
    allFiImages: allContentfulProduct(
      filter: {
        brand: {
          contentful_id: { eq: $contentful_id }
          node_locale: { eq: "fi" }
        }
      }
    ) {
      edges {
        node {
          node_locale
          contentful_id
          image {
            fluid(maxWidth: 768, maxHeight: 768) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulSubCategory(
      filter: {
        contentful_id: { in: $productTabs }
        node_locale: { eq: $locale }
        brand: { elemMatch: { contentful_id: { eq: $contentful_id } } }
      }
    ) {
      edges {
        node {
          id
          title
          product {
            id
            contentful_id
            title
            slug
            node_locale
            body {
              ... on contentfulProductBodyRichTextNode {
                id
                json
              }
            }
            image {
              fluid(maxWidth: 768, maxHeight: 768) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    contentfulBrand(id: { eq: $id }) {
      title
      description {
        description
      }
      image {
        contentful_id
        title
      }
      homepage
      product {
        id
        contentful_id
        title
        slug
        node_locale
        body {
          ... on contentfulProductBodyRichTextNode {
            id
            json
          }
        }
        image {
          fluid(maxWidth: 768, maxHeight: 768) {
            ...GatsbyContentfulFluid
          }
        }
      }
      contentBlocks {
        __typename
        ... on ContentfulThreeColumnBlock {
          title
          id
          columns {
            id
            title
            image {
              contentful_id
              title
            }
            body {
              json
            }
            button {
              title
              slug
            }
          }
        }
      }
    }
  }
`
