import React from "react"
import { shape, string } from "prop-types"
import styled from "@emotion/styled"
import { Flex, Box } from "rebass"
import MarkdownView from "react-showdown"
import BlockWrapper from "../atoms/block-wrapper"
import PageTitle from "../atoms/page-title"
import Heading2 from "../atoms/heading-2"

const propTypes = {
  theme: shape().isRequired,
  title: string.isRequired,
  description: shape(),
  homepage: string,
}

const defaultProps = {
  description: null,
  homepage: null,
}

const StyledBrandLink = styled(Heading2)`
  text-decoration: none;
  color: ${({ theme }) => theme.currentColours.fg};
`

function BrandHeader({ theme, title, description, homepage }) {
  let homepageName = ""
  if (homepage && homepage.includes("//")) {
    homepageName = homepage
      .replace("https://", "")
      .replace("http://", "")
      .replace("/", "")
  }
  return (
    <BlockWrapper>
      <Flex mx={theme.flexGutter} flexWrap="wrap">
        <Box width={[1, 1, 1 / 3]} px={theme.boxGutter}>
          <PageTitle>{title}</PageTitle>
        </Box>
        <Box width={[1, 1, 1 / 3]} px={theme.boxGutter}>
          {homepage && (
            <StyledBrandLink as="a" href={homepage} target="_blank">
              {homepageName}
            </StyledBrandLink>
          )}
        </Box>
        {description && (
          <Box width={[1, 1, 1 / 3]} px={theme.boxGutter}>
            <MarkdownView markdown={description.description} />
          </Box>
        )}
      </Flex>
    </BlockWrapper>
  )
}

BrandHeader.propTypes = propTypes
BrandHeader.defaultProps = defaultProps

export default BrandHeader
