import React from "react"
import { shape, arrayOf, node, string } from "prop-types"
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs"
import styled from "@emotion/styled"
import Fade from "react-reveal/Fade"
import { Flex, Box } from "rebass"
import themeFile from "../../config/theme"
import Card from "./card"
import { getFallbackImageFluid } from "../../helpers/index"

const propTypes = {
  productTabs: arrayOf(
    shape({
      title: string,
      id: string,
      products: arrayOf(shape()),
    })
  ).isRequired,
  productImages: arrayOf(shape()).isRequired,
}

const TabButtons = styled.div`
  margin-bottom: 1.5em;
  text-align: center;
`

const TabButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.standardFamily};
  font-weight: ${props => (props.active === "true" ? "600" : "300")};
  padding: 0.826em 0.826em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.black};

  -webkit-appearance: none;
  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    color: ${({ theme }) => theme.currentColours.fg};
  }
`

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState()
  return (
    <TabButton active={isActive ? "true" : "false"} onClick={onClick}>
      {children}
    </TabButton>
  )
}
Tab.propTypes = {
  children: node.isRequired,
}

const Panel = ({ children }) => {
  const isActive = usePanelState()
  return isActive ? <div>{children}</div> : null
}
Panel.propTypes = {
  children: node.isRequired,
}

const Products = ({ productImages, productTabs }) => {
  return (
    <div>
      <Tabs>
        {productTabs && productTabs.length > 1 && (
          <TabButtons>
            {productTabs &&
              productTabs.map(t => {
                return <Tab key={t.id}>{t.title}</Tab>
              })}
          </TabButtons>
        )}
        {productTabs && (
          <div>
            {productTabs &&
              productTabs.map(t => {
                return (
                  <Panel as="div" key={t.id}>
                    <Flex mx={themeFile.flexGutter} flexWrap="wrap">
                      {t.products &&
                        t.products.map((p, index) => {
                          return (
                            <Box
                              width={[1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 4]}
                              px={themeFile.boxGutter}
                              key={p.id}
                            >
                              <Fade
                                force
                                bottom
                                distance="3em"
                                duration={600}
                                delay={100 * index}
                              >
                                <Card
                                  slug={p.slug}
                                  fluid={
                                    p.image && p.image.fluid
                                      ? p.image.fluid
                                      : null
                                  }
                                  fallbackFluid={getFallbackImageFluid(
                                    productImages,
                                    p.contentful_id
                                  )}
                                  body={p.body}
                                  title={p.title}
                                />
                              </Fade>
                            </Box>
                          )
                        })}
                    </Flex>
                  </Panel>
                )
              })}
          </div>
        )}
      </Tabs>
    </div>
  )
}

Products.propTypes = propTypes

export default Products
